// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import $ from 'jquery';
import "./controllers";
import * as bootstrap from "bootstrap";
import * as toastr from "toastr/toastr";
import select2 from 'select2';
select2($);
import "./themes/default/application";

const OrseddSite = {
  flash_notice: function(type, message) {
    toastr[type](message)
  },

console_notice: function(message) {
    console.log("message" + message)
  }
}

window.OrseddSite = OrseddSite;

let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
let popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
  return new bootstrap.Popover(popoverTriggerEl)
});

let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
let tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
  return new bootstrap.Tooltip(tooltipTriggerEl)
});

let dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
let dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
  return new bootstrap.Dropdown(dropdownToggleEl)
});


document.addEventListener("turbo:load",function(){
  let items = document.querySelectorAll('.feedCarousel .carousel-item');
  items.forEach((el) => {
    const minPerSlide = 4
    let next = el.nextElementSibling
    for (let i=1; i < minPerSlide; i++) {
      if (!next) {
        // wrap carousel by using first child
        next = items[0]
      }
      let cloneChild = next.cloneNode(true)
      el.appendChild(cloneChild.children[0])
      next = next.nextElementSibling
    }
  });

  $('select').select2();


  $('.js-select2-tags').select2({tags: true});

  // var pageHeight = window.innerHeight;
  // $('.banner').css( { height: `calc(${pageHeight}px - 598px)` });
  var pageHeight = $(window).height() - 350;
  $('.banner.setHeight').height(pageHeight);

  $("body").on("change", "#page_page_type", function () {
    $('#page_slug').val(this.value);
  });

  let contentFormActionList = [].slice.call(document.querySelectorAll('.content-form-action'));
  contentFormActionList.forEach(function (contentFormActionEl) {
    contentFormActionEl.addEventListener("click", function(event){
      let form = event.target.closest('form');
      form.submit();
    });
  });

});

$("body").on("click", "a.delete-home-page-service", function () {
  this.parentElement.parentElement.remove();
})


$("body").on("click", "a.add-new-service", function () {
  let serviceHTML = document.getElementById('service-template').innerHTML;
  serviceHTML = serviceHTML.replaceAll('position-placeholder', document.getElementsByClassName("nested-services").length);
  serviceHTML = serviceHTML.replaceAll('index-placeholder', document.getElementsByClassName("nested-services").length);
  document.getElementById('services-add-new').insertAdjacentHTML('beforebegin', serviceHTML);
})


$("body").on("change", "input#searchInput", function (e) {
  document.getElementById('searchForm').submit();
})

$(document).on('turbo:load', function() {
  $(window).scroll(function() {
    var next_url = $("ul.pagination a[rel='next']").attr('href');
    if (next_url && ($(document).height() - $(this).height() == $(this).scrollTop())) {
      $.ajax({
        url: next_url,
        headers: {"Accept": "application/json", "Content-Type": "application/json"},
        dataType: 'json',
        context: document.body
      }).done(function(data) {
        $(".content__main").append(data.feed_contents);
        $('#pagination').html(data.paginations);
      });
    }
  });
});

$(function() {
  $('.show_more').click(function(e) {
    e.preventDefault();
    var textElement = this.previousSibling;
    this.nextSibling.style.display = 'block'
    this.style.display = 'none';
    textElement.classList.remove("text-truncate");
  });

  $('.show_less').click(function(e) {
    e.preventDefault();
    var show_more = this.previousSibling;
    var textElement = show_more.previousSibling;

    this.style.display = 'none';
    show_more.style.display = 'block';
    textElement.classList.add("text-truncate");
  });
});

/**
 * Dark Light Mode
 * Header Connect
 * Loadmore Item
 * headerFixed
 * retinaLogo
 * ajaxContactForm
 * mobileNav
 * ajaxSubscribe
 * alertBox
 * loadmore
 */

import $ from 'jquery';
import "./swiper_initializers.js";
// import "./switchmode.js";
import "./mouse.js";
import "./mobile_navigation.js";

//Scroll back to top
function handlePreloader() {
  if ($(".preloader").length) {
    $("body").addClass("page-loaded");
    $(".preloader").delay(400).fadeOut(0);
  }
}

var themesflatTheme = {
  // Main init function
  init: function () {
    this.config();
    this.events();
  },

  // Define vars for caching
  config: function () {
    this.config = {
      $window: $(window),
      $document: $(document),
    };
  },

  // Events
  events: function () {
    var self = this;

    // Run on document ready
    self.config.$document.on("ready", function () {
      // Retina Logos
      self.retinaLogo();
    });

    // Run on Window Load
    self.config.$window.on("load", function () {});
  },

  // Retina Logos
  retinaLogo: function () {
    var retina = window.devicePixelRatio > 1 ? true : false;
    var $logo = $("#site-logo img");
    var $logo2 = $("#logo-footer img");
    var $logo_retina = $logo.data("retina");

    if (retina && $logo_retina) {
      $logo.attr({
        src: $logo.data("retina"),
        width: $logo.data("width"),
        height: $logo.data("height"),
      });
    }
    if (retina && $logo_retina) {
      $logo2.attr({
        src: $logo.data("retina"),
        width: $logo.data("width"),
        height: $logo.data("height"),
      });
    }
  },
}; // end themesflatTheme

// Start things up
themesflatTheme.init();

// Header Fixed
var headerFixed = function () {
  if ($("body").hasClass("header-fixed")) {
    var nav = $("#header_main");
    if (nav.length) {
      var offsetTop = nav.offset().top,
        headerHeight = nav.height(),
        injectSpace = $("<div />", {
          height: headerHeight,
        }).insertAfter(nav);
      $(window).on("load scroll", function () {
        if ($(window).scrollTop() > 200) {
          nav.addClass("is-fixed");
          injectSpace.show();
        } else {
          nav.removeClass("is-fixed");
          injectSpace.hide();
        }

        if ($(window).scrollTop() > 400) {
          nav.addClass("is-small");
        } else {
          nav.removeClass("is-small");
        }
      });
    }
  }
};

var goTop = function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() > 800) {
      $("#scroll-top").addClass("show");
    } else {
      $("#scroll-top").removeClass("show");
    }
  });

  $("#scroll-top").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, 200, "easeInOutExpo");
    return false;
  });
};


var alertBox = function () {
  $(document).on("click", ".close", function (e) {
    $(this).closest(".flat-alert").remove();
    e.preventDefault();
  });
};

var flatAccordion = function () {
  var args = { duration: 100 };
  $(".flat-toggle .toggle-title.active").siblings(".toggle-content").show();

  $(".flat-toggle.enable .toggle-title").on("click", function () {
    $(this).closest(".flat-toggle").find(".toggle-content").slideToggle(args);
    $(this).toggleClass("active");
  }); // toggle

  $(".flat-accordion .toggle-title").on("click", function () {
    $(".flat-accordion .flat-toggle").removeClass("active");
    $(this).closest(".flat-toggle").toggleClass("active");

    if (!$(this).is(".active")) {
      $(this)
        .closest(".flat-accordion")
        .find(".toggle-title.active")
        .toggleClass("active")
        .next()
        .slideToggle(args);
      $(this).toggleClass("active");
      $(this).next().slideToggle(args);
    } else {
      $(this).toggleClass("active");
      $(this).next().slideToggle(args);
      $(".flat-accordion .flat-toggle").removeClass("active");
    }
  }); // accordion
};

var flatCounter = function () {
  if ($(document.body).hasClass("counter-scroll")) {
    var a = 0;
    $(window).scroll(function () {
      var oTop = $(".counter").offset().top - window.innerHeight;
      if (a == 0 && $(window).scrollTop() > oTop) {
        if ($().countTo) {
          $(".counter")
            .find(".number")
            .each(function () {
              var to = $(this).data("to"),
                speed = $(this).data("speed");

              $(this).countTo({
                to: to,
                speed: speed,
              });
            });
        }
        a = 1;
      }
    });
  }
};

var flattabs = function () {
  $(".flat-tabs").each(function () {
    $(this).find(".content-tab").children().hide();
    $(this).find(".content-tab").children().first().show();
    $(this)
      .find(".menu-tab")
      .children("li")
      .on("click", function () {
        var liActive = $(this).index();
        var contentActive = $(this)
          .siblings()
          .removeClass("active")
          .parents(".flat-tabs")
          .find(".content-tab")
          .children()
          .eq(liActive);
        contentActive.addClass("active").fadeIn("slow");
        contentActive.siblings().removeClass("active");
        $(this)
          .addClass("active")
          .parents(".flat-tabs")
          .find(".content-tab")
          .children()
          .eq(liActive)
          .siblings()
          .hide();
      });
  });
};

const popupVideo = function () {
  if ($().magnificPopup) {
    $(".popup-youtube").magnificPopup({
      type: "iframe",
      mainClass: "mfp-fade",
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,
    });
  }
};

  // Dom Ready
document.addEventListener("turbo:load",function(){
  $(".icon-star").on("click", function () {
    $(this).toggleClass("active");
  });

  // Show pass
  $(".toggle-password").click(function () {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $(".pass");
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  $(".toggle-password1").click(function () {
    $(this).toggleClass("fa-eye fa-eye-slash");
    var input = $(".pass1");
    if (input.attr("type") == "password") {
      input.attr("type", "text");
    } else {
      input.attr("type", "password");
    }
  });

  // Sidebar Toggle
  $(".btn").click(function () {
    $(this).toggleClass("click");
    $(".dashboard__sidebar").toggleClass("show");
    $(".dashboard").toggleClass("show");
  });

  $(".feat-btn").click(function () {
    $("nav ul .feat-show").toggleClass("show");
  });

  $(".sidebar__menu li").click(function () {
    $(this).addClass("active").siblings().removeClass("active");
  });

  const url = window.location.pathname,
    urlRegExp = new RegExp(url.replace(/\/$/, "") + "$"); // create regexp to match current url pathname and remove trailing slash if present as it could collide with the link in navigation in case trailing slash wasn't present there
  // now grab every link from the navigation
  $(".sidebar__menu a").each(function () {
    // and test its normalized href against the url pathname regexp
    if (urlRegExp.test(this.href.replace(/\/$/, ""))) {
      $(this).addClass("active");
    }
  });
  // End Sidebar Toggle

  handlePreloader();
  headerFixed();
  goTop();
  alertBox();
  flatAccordion();
  flatCounter();
  flattabs();
  popupVideo();
});
